

import {Link, Trans, useTranslation, useI18next} from 'gatsby-plugin-react-i18next';



import React from "react"
import i18next from '../i18n/config';
// import ImgHeader from "../img/A_glowna_1/tlo_glowna.jpg";
import ImgOurCoffeeBack from "../img/D_kawa_ziarnista_18/iko_powrot_nasze_kawy.png";
import ImgCoffeeBeanMain from "../img/H_opakowania_kawa_ziarnista_15/ziarno_stojaca.png";
import ImgCoffeeBeans from "../img/H_opakowania_kawa_ziarnista_15/ziarna_tlo_opak.png";
import ImgWholecon from "../img/H_opakowania_kawa_ziarnista_15/iko_opak_ziarno.png";

export default function Header(props) {



    return (
            <React.Fragment>

                <section id="wholebean" class="coffeebeans firstSection">
                    <h1>{i18next.t('WholeBeansH1')}</h1>
                    <p>{i18next.t('WholeBeansP')}</p>
                    <a>
                        <div class="icon torebka" onClick={() => document.scrolltosection("stand-up-bag")} >
                            <div><img loading="lazy" src={ImgWholecon} alt="Instanta Ground Bag with coffee" /></div>
                            <p>{i18next.t('WholeBeanPackagingLink1')}</p>
                        </div>
                    </a>

                    <div class="beans">


                        <picture>
                            <source media="(min-width: 640px)" srcset="/img/OurCoffees/ziarna_tlo_opak.png" />
                            <img loading="lazy" src="/img/OurCoffees/ziarna_tlo_opak_mob.png" alt="Instanta Coffee Beans" />
                        </picture>



                    </div>
                    <aside class="mininavi">
                        <div>
                            <Link to={'/OurPackaging/Packaging/'}>
                            <img loading="lazy" src={ImgOurCoffeeBack} alt="Instanta Coffee Back Icon" />
                            <p>{i18next.t('CapsulesMiniNaviBack')}</p>
                            </Link>
                        </div>
                    </aside>

                </section>

                <section id="stand-up-bag" class="freeze-dried main">
                    <div class="image"><img loading="lazy" src={ImgCoffeeBeanMain} loading="lazy" alt="Instanta Coffee Capsules with Ground Coffee" />
                    </div>
                    <div class="text">
                        <h2>{i18next.t('WholeBeansSectionH2')}</h2>
                        <p>{i18next.t('WholeBeansSectionP')}</p>
                        <div>
                            <div class="links">
                                <div class="link">
                                    <Link to={'/Collaboration/'}>
                                    <p>{i18next.t('CapsulesLinkCollaboration')}</p>
                                    </Link>
                                </div>
                                <div class="link">
                                    <Link to={'/OurPackaging/Design/'}>
                                    <p>{i18next.t('CustomProductLink3')}</p>
                                    </Link>
                                </div>
                                <div class="link">
                                    <Link to={'/OurCoffees/CoffeeBeans/'}>
                                    <p>{i18next.t('WholeBeansLink1')}</p>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>

            )


}
